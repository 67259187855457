import { Select } from "antd";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { GlobalContext } from "../../context/GlobalContext";
import useFetchCollection from "../../../hooks/useFetchCollection";
import { multipleClientsByIds } from "../../../helpers/fetchData/fetchClients";
import { filterLabelOptions } from "../../../utils/filterLabelOptions";

const MAX_TAG_COUNT = 10;

const DEFAULT_FILTER = { type: "CLIENT" }; //INICIAR FUERA DEL COMPONENTE PARA EVITAR EJECUCIONES EN RENDERIZADOS

export const SelectClientes = ({
  mode = "",
  value,
  setValue,
  maxWidth = null,
  placeholder = "",
  existingClients = [],
}) => {
  const { instance, clientes, setClientes } = useContext(GlobalContext);

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");

  const [collectionLength, setCollectionLength] = useState(0);

  const [optionsMap, setOptionsMap] = useState(new Map());

  const [innerSearchValue, setInnerSearchValue] = useState("");

  const [checkedInitialValues, setCheckedInitialValues] = useState(false);

  const addOptions = (newOptions) => {
    setOptionsMap((prev) => {
      const updated = new Map(prev);

      newOptions.forEach((option) => {
        updated.set(option._id, option);
      });

      return updated;
    });
  };

  useEffect(() => {
    if (existingClients.length) {
      addOptions(existingClients);
    }
  }, [existingClients]);

  const debouncedSearch = useCallback(
    (value) => {
      setSearch(value);
      setPage(1);
    },
    [setSearch, setPage]
  );

  useEffect(() => {
    const handler = setTimeout(() => {
      debouncedSearch(innerSearchValue);
    }, 500); // Debounce delay of 500ms

    // Si el usuario vuelve a escribir antes de 500ms, se cancela el seteo de search (deboucedSearch)
    return () => clearTimeout(handler);
  }, [innerSearchValue, debouncedSearch]);

  const { data, loading, error } = useFetchCollection(
    "clients/getClients",
    page,
    20,
    search,
    DEFAULT_FILTER
  );

  const handlePopupScroll = (e) => {
    const { target } = e;

    if (
      !loading &&
      options?.length < collectionLength &&
      target.scrollTop + target.offsetHeight === target.scrollHeight
    ) {
      // Esto va a triggerear otro fetch;
      setPage((prevState) => (prevState += 1));
    }
  };

  const handleOnSearch = (value) => {
    setInnerSearchValue(value);
    debouncedSearch(value);
  };

  useEffect(() => {
    if (Array.isArray(data?.items) && data?.items?.length) {
      addOptions(data?.items);

      if (data.collectionTotal && data.collectionTotal !== collectionLength) {
        // Si no está seteado el length, lo seteo.
        setCollectionLength(data.collectionTotal);
      }
    }
  }, [data]);

  const options = useMemo(() => {
    return Array.from(optionsMap.values()).map((item) => {
      return {
        label: `${item.idSistema} - ${item.name}`,
        value: item._id,
      };
    });
  }, [optionsMap]);

  useEffect(() => {
    // Iniciar con los cuentas que hay.
    // No incluir cuentas como dependencia
    if (clientes?.length > 0) {
      addOptions(clientes);
    }
  }, []);

  useEffect(() => {
    // Actualizo array de cuentas tambien.
    setClientes(() => Array.from(optionsMap, ([key, value]) => value));
  }, [optionsMap]);

  const handleChange = (v) => {
    setCheckedInitialValues(true);
    setValue(v);
  };

  const validateInitialValues = async () => {
    if (existingClients.length) {
      return;
    }
    // Checkeo que los valores preseleccionados existan como contactos;
    if (checkedInitialValues) {
      // Se valida solo una vez
      return;
    }

    if (!instance?.userData?.apiKey) {
      return;
    }

    if (!value) {
      return;
    }

    let idsArray = [];

    if (Array.isArray(value)) {
      idsArray = [...value];
    } else {
      // Selección única
      idsArray.push(value);
    }

    if (idsArray?.length > 0) {
      // se buscan idsArray
      try {
        const res = await multipleClientsByIds(
          instance?.userData?.apiKey,
          idsArray
        );

        if (res?.status === 200) {
          if (Array.isArray(res?.data)) {
            addOptions(res.data);

            // se recibe res.data
            let idsObtenidos = res.data.map((element) => element._id);

            // Buscar cuales hay que sacar.
            let idsADescartar = idsArray.filter(
              (element) => !idsObtenidos?.includes(element)
            );

            // Revisar si hay contactos seleccionados que ya no existen.
            if (Array.isArray(value)) {
              // Limpio los contactos que ya no existen.
              const filteredValue = value?.filter(
                (element) => !idsADescartar.includes(element)
              );

              setValue(filteredValue);
            }
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        setCheckedInitialValues(true);
      }
    }
  };

  useEffect(() => {
    validateInitialValues();
  }, [value, instance]);

  return (
    <Select
      mode={mode}
      value={!checkedInitialValues && loading ? null : value}
      allowClear
      style={{
        width: "100%",
        maxWidth: maxWidth,
      }}
      maxTagCount={MAX_TAG_COUNT}
      placeholder={
        placeholder
          ? placeholder
          : mode === "multiple"
          ? "Seleccione cuentas"
          : "Seleccione una cuenta"
      }
      onChange={handleChange}
      options={options || []}
      showSearch
      filterOption={filterLabelOptions}
      virtual={true}
      onPopupScroll={(e) => handlePopupScroll(e)}
      onSearch={handleOnSearch}
      loading={loading}
    />
  );
};
