import axios from "axios";
import { API_URL } from "../../config/ENV_CONSTS";

export const newInvitation = async (key, data) => {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${API_URL}/instance/newInvitation`,
    headers: {
      Authorization: key,
      "Content-Type": "application/json",
    },
    data: data,
  };
  const res = axios(config);

  return await res;
};

export const resendInvitation = async (key, data) => {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${API_URL}/instance/resendInvitation`,
    headers: {
      Authorization: key,
      "Content-Type": "application/json",
    },
    data: data,
  };
  const res = await axios(config);

  return res;
};

export const manageInvitation = async (data) => {
  try {
    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${API_URL}/instance/manageInvitation`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const getInvitationsByInstance = async (key) => {
  try {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${API_URL}/instance/getInvitationsByInstance`,
      headers: {
        Authorization: key,
        "Content-Type": "application/json",
      },
    };

    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};
