import dayjs from 'dayjs';

export function getCumpleanios(fechaNacimiento) {
  if (fechaNacimiento) {
    const fechaCorriente = dayjs();
    const formattedFechaNacimiento = dayjs(fechaNacimiento, 'DD/MM/YYYY');

    const edad = fechaCorriente.diff(formattedFechaNacimiento, 'years');

    return `${fechaNacimiento} (${edad} años)`;
  }

  return '-';
}
