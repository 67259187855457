import axios from "axios";
import { API_URL } from "../../config/ENV_CONSTS";

export const getContacts = async (instance) => {
  try {
    let config = {
      method: "get",
      url: `${API_URL}/contacts/getContacts`,
      headers: {
        Authorization: `${instance.userData?.apiKey}`,
        "Content-Type": "application/json",
      },
    };
    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const newContact = async (instance, data) => {
  const config = {
    method: "post",
    url: `${API_URL}/contacts/addContact`,
    data: data,
    headers: {
      Authorization: `${instance?.userData?.apiKey}`,
      "Content-Type": "application/json",
    },
  };

  const res = axios(config);

  return await res;
};

export const editContact = async (instance, data) => {
  const config = {
    method: "put",
    url: `${API_URL}/contacts/editContact`,
    data: data,
    headers: {
      Authorization: `${instance?.userData?.apiKey}`,
      "Content-Type": "application/json",
    },
  };

  const res = axios(config);

  return await res;
};

export const executeExternalContactsLoad = async (key) => {
  if (key) {
    let config = {
      method: "get",
      url: `${API_URL}/contacts/executeExternalContactsLoad`,
      headers: {
        Authorization: key,
      },
    };

    const res = axios(config);

    return await res;
  }
};

export const executeExternalContactsLoadExcel = async (key, data) => {
  if (key) {
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${API_URL}/contacts/executeExternalContactsLoadExcel`,
      headers: {
        Authorization: key,
        "Content-Type": "application/json",
      },
      data: data,
    };

    const res = axios(config);

    return await res;
  }
};

export const associateContactToClients = async (key, data) => {
  try {
    if (key) {
      const config = {
        method: "put",
        url: `${API_URL}/contacts/associateContactToClients`,
        headers: {
          Authorization: key,
        },
        data: data,
      };

      const res = await axios(config);

      return res;
    }
  } catch (err) {
    console.error(err);
  }
};

export const multipleContactsByIds = async (key, data) => {
  try {
    const config = {
      method: "post",
      url: `${API_URL}/contacts/multipleContactsByIds`,
      headers: {
        Authorization: key,
      },
      data: { ids: data },
    };

    const res = await axios(config);

    return res;
  } catch (err) {
    console.error(err);
  }
};

export const getContact = async (instance, params) => {
  try {
    let config = {
      method: "get",
      url: `${API_URL}/contacts/getContact`,
      headers: {
        Authorization: `${instance.userData?.apiKey}`,
      },
      params,
    };
    const res = await axios(config);

    return res;
  } catch (err) {
    console.log(err);
  }
};

// CONTACTOS ARCHIVADOS:
export const archiveContact = async (instance, phoneNumber) => {
  try {
    const config = {
      method: "put",
      url: `${API_URL}/contacts/archiveContact/${phoneNumber}`,
      headers: {
        Authorization: instance?.userData?.apiKey,
      },
    };

    const res = await axios(config);

    return res;
  } catch (err) {
    console.error(err);
  }
};

export const unarchiveContact = async (instance, item) => {
  try {
    const config = {
      method: "put",
      url: `${API_URL}/contacts/unarchiveContact/${item.phones?.[0]?.phone}`,
      headers: {
        Authorization: instance?.userData?.apiKey,
      },
      data: {
        number: item.phones?.[0]?.phone,
      },
    };

    const res = await axios(config);

    return res;
  } catch (err) {
    console.error(err);
  }
};

export const getArchivedContacts = async (instance) => {
  try {
    const config = {
      method: "get",
      url: `${API_URL}/contacts/getArchivedContacts`,
      headers: {
        Authorization: instance?.userData?.apiKey,
      },
    };

    const res = await axios(config);

    return res;
  } catch (err) {
    console.error(err);
  }
};

export const deleteContact = async (instance, id) => {
  try {
    const config = {
      method: "delete",
      url: `${API_URL}/contacts/deleteContact/${id}`,
      headers: {
        Authorization: instance?.userData?.apiKey,
      },
    };

    const res = await axios(config);

    return res;
  } catch (err) {
    console.error(err);
  }
};

export const deleteMultipleContacts = async (instance, ids) => {
  try {
    const config = {
      method: "post",
      url: `${API_URL}/contacts/deleteMultipleContacts`,
      headers: {
        Authorization: instance?.userData?.apiKey,
      },
      data: {
        ids,
      },
    };

    const res = await axios(config);

    return res;
  } catch (err) {
    console.error(err);
  }
};

export const unarchiveMultipleContacts = async (instance, ids) => {
  try {
    const config = {
      method: "put",
      url: `${API_URL}/contacts/unarchiveMultipleContacts`,
      headers: {
        Authorization: instance?.userData?.apiKey,
      },
      data: {
        ids,
      },
    };

    const res = await axios(config);

    return res;
  } catch (err) {
    console.error(err);
  }
};
