import { API_URL } from "../../../../../config/ENV_CONSTS";

export const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};

export const getXlsData = async (file) => {
  try {
    let myHeaders = new Headers();

    const blob = new Blob([file], { type: file.type });

    let formData = new FormData();
    formData.append("messaging_product", "whatsapp");
    formData.append("file", blob, file.name);

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formData,
      redirect: "follow",
    };

    let res = await fetch(`${API_URL}/processFile`, requestOptions);

    if (!res.ok) {
      throw new Error(`HTTP error! Status: ${res?.status}`);
    }

    let result = await res.text();
    return result;
  } catch (error) {
    console.log(error);
    return null;
  }
};
