import { updateUserPicture } from "../helpers/fetchData/fetchUsers";

export const checkUserPicture = (usuarios, user, instance, setUsuarios) => {
  try {
    if (!instance?.userData?.apiKey) {
      return;
    }

    if (user?.picture && usuarios) {
      let savedUser = usuarios.find((item) => item.email === user.email);

      if (savedUser) {
        if (user.picture !== savedUser.picture) {
          // Si la imagen de los usuarios no es la misma que la que tiene el user autenticado, actualizar.
          updateUserPicture(instance?.userData?.apiKey, user.picture)
            .then((res) => {
              if (res?.status === 200) {
                setUsuarios((prevState) => {
                  return prevState.map((item) => {
                    if (item.email === user.email) {
                      return { ...item, picture: user.picture };
                    }

                    return item;
                  });
                });
              }
            })
            .catch((error) => console.log(error));
        }
      }
    }
  } catch (error) {
    console.log("error", error);
  }
};
