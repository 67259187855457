import axios from "axios";
import { API_URL } from "../../config/ENV_CONSTS";

export const recopilarInfo = async () => {
  try {
    const config = {
      url: `${API_URL}/info`,
      method: "get",
    };

    const res = await axios.request(config);
  } catch (error) {
    console.log("error", error);
  }
};
