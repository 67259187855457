import axios from "axios";
import { API_URL } from "../../config/ENV_CONSTS";

export const executeExternalClientsLoad = async (key) => {
  if (key) {
    let config = {
      method: "get",
      url: `${API_URL}/clients/executeExternalClientsLoad`,
      headers: {
        Authorization: key,
      },
    };

    const res = axios(config);

    return await res;
  }
};

export const executeExternalClientsLoadExcel = async (key, data) => {
  if (key) {
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${API_URL}/clients/executeExternalClientsLoadExcel`,
      headers: {
        Authorization: key,
        "Content-Type": "application/json",
      },
      data: data,
    };

    const res = axios(config);

    return await res;
  }
};

export const getAllClients = async (key) => {
  let config = {
    method: "get",
    url: `${API_URL}/clients/getAllClients`,
    headers: {
      Authorization: key,
      "Content-Type": "application/json",
    },
  };

  const res = axios(config);

  return await res;
};

export const multipleClientsByIds = async (key, data) => {
  try {
    const config = {
      method: "post",
      url: `${API_URL}/clients/multipleClientsByIds`,
      headers: {
        Authorization: key,
      },
      data: { ids: data },
    };

    const res = await axios(config);

    return res;
  } catch (err) {
    console.error(err);
  }
};

export const getAssociatedClients = async (instance, id) => {
  try {
    const config = {
      method: "get",
      url: `${API_URL}/clients/getAssociatedClients/${id}`,
      headers: {
        Authorization: instance?.userData?.apiKey,
      },
    };

    const res = await axios(config);

    return res;
  } catch (err) {
    console.error(err);
  }
};
