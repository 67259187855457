export function getTipoDireccion(tipo) {
  switch (tipo.toLowerCase()) {
    case 'home':
      return 'Hogar';
    case 'work':
      return 'Trabajo';
    default:
      break;
  }
}
