import axios from "axios";
import { API_URL } from "../../config/ENV_CONSTS";

export const createProduct = async (key, data) => {
  try {
    if (key) {
      let config = {
        method: "post",
        url: `${API_URL}/products/createProduct`,
        headers: {
          Authorization: key,
        },
        data: { product: data },
      };

      const res = axios(config);

      return await res;
    }
  } catch (error) {
    console.log(error);
  }
};

export const updateProduct = async (key, data) => {
  try {
    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${API_URL}/products/editProduct`,
      headers: {
        Authorization: key,
        "Content-Type": "application/json",
      },
      data: data,
    };

    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const executeExternalProductsLoad = async (key) => {
  try {
    if (key) {
      let config = {
        method: "get",
        url: `${API_URL}/products/executeExternalProductsLoad`,
        headers: {
          Authorization: key,
        },
      };

      const res = axios(config);

      return await res;
    }
  } catch (error) {
    console.log(error);
  }
};
