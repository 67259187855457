import { Col, Row } from "antd";
import SideMenu from "./menu/SideMenu";
import SideDrawer from "./sideDrawer/SideDrawer";
import { useContext } from "react";
import { GlobalContext } from "../../context/GlobalContext";
import RightDrawer from "./rightDrawer/RightDrawer";
import LoadingIcon from "../Loader/LoadingIcon";
import { useAuth0 } from "@auth0/auth0-react";

const MainLayout = ({ children }) => {
  const { loadingInstance, loadingApplication } = useContext(GlobalContext);

  const { isAuthenticated } = useAuth0();

  return (
    <Row className="flexContainer">
      <Col className={!loadingApplication ? "sidebar" : "sidebar-animated"}>
        <SideMenu />
        <SideDrawer />
        <RightDrawer />
      </Col>
      <p className="mensaje-mediaquery">
        Por favor <br />
        Utilice un dispositivo <br /> con mayor resolución
      </p>
      <Col className="mainView">
        {loadingInstance || !isAuthenticated ? (
          <div className="main-loading-container">
            {/* <Loader /> */}
            <LoadingIcon size="mainLoader" />
          </div>
        ) : (
          <Row
            className={
              !loadingApplication ? "pageContent" : "pageContent-animated"
            }
          >
            <Col className="col-mainchildren">{children}</Col>
            {/* {!window.location.pathname.includes("/flows/") && (
            <span className="brocoly-water-mark">
              <Image
                src="https://binamics.nyc3.digitaloceanspaces.com/logos/logo-brocoly-02.png"
                preview={false}
                width={40}
              />
            </span>
          )} */}
          </Row>
        )}
      </Col>
    </Row>
  );
};

export default MainLayout;
