import axios from "axios";
import { API_URL } from "../../config/ENV_CONSTS";

export function getAdditionalFields(
  instance,
  messageApi,
  setAdditionalFields,
  setLoading = null
) {
  if (setLoading) {
    setLoading(true);

    const config = {
      method: "get",
      url: `${API_URL}/additionalFields/getAdditionalFields`,
      headers: {
        Authorization: instance?.userData?.apiKey,
      },
    };

    axios(config)
      .then((res) => {
        if (res?.status === 200) {
          const data = res.data;

          if (data && Array.isArray(data)) {
            const activeAdditionalFields = data.filter(
              (field) => !field.metadata?.deletedAt
            );

            setAdditionalFields(activeAdditionalFields);
          } else {
            messageApi.error("No se pudo cargar los campos adicionales");
          }
        }
      })
      .catch((error) => {
        console.error(error);
        messageApi.error("No se pudo cargar los campos adicionales");
      })
      .finally(() => {
        if (setLoading) {
          setLoading(false);
        }
      });
  }
}

export function addAdditionalField(
  instance,
  messageApi,
  formValues,
  setAdditionalFields,
  setLoading = null,
  setOpen
) {
  if (setLoading) {
    setLoading(true);
  }

  const config = {
    method: "post",
    url: `${API_URL}/additionalFields/addAdditionalField`,
    headers: {
      Authorization: instance?.userData?.apiKey,
    },
    data: formValues,
  };

  axios(config)
    .then((res) => {
      if (res?.status === 200) {
        setAdditionalFields((prevState) => {
          let newArray = [...prevState];

          newArray.push({
            _id: res.data._id,
            ...formValues,
          });

          return newArray;
        });
        setOpen(false);
        messageApi.success("Campo adicional creado");
      }
    })
    .catch((error) => {
      console.error(error);
      if (error.response?.status) {
        messageApi.error(error.response.data.error);
      } else {
        messageApi.error("No se pudo crear el campo adicional");
      }
    })
    .finally(() => {
      if (setLoading) {
        setLoading(false);
      }
    });
}

export function editAdditionalField(
  instance,
  messageApi,
  id,
  formValues,
  setAdditionalFields,
  setLoading = null,
  setOpen
) {
  if (setLoading) {
    setLoading(true);
  }

  const config = {
    method: "put",
    url: `${API_URL}/additionalFields/editAdditionalField/${id}`,
    headers: {
      Authorization: instance?.userData?.apiKey,
    },
    data: formValues,
  };

  axios(config)
    .then((res) => {
      if (res?.status === 200) {
        setAdditionalFields((prevState) => {
          let newArray = [...prevState];

          const index = newArray.findIndex((element) => element._id === id);

          if (index !== -1) {
            newArray[index] = { ...newArray[index], ...formValues };
          }

          return newArray;
        });

        setOpen(false);

        messageApi.success("Campo adicional editado");
      }
    })
    .catch((error) => {
      console.error(error);
      if (error.response?.status) {
        messageApi.error(error.response.data.error);
      } else {
        messageApi.error("No se pudo editar el campo adicional");
      }
    })
    .finally(() => {
      if (setLoading) {
        setLoading(false);
      }
    });
}

export function deleteAdditionalField(
  instance,
  messageApi,
  id,
  setAdditionalFields,
  setLoading = null
) {
  if (setLoading) {
    setLoading(true);
  }

  const config = {
    method: "delete",
    url: `${API_URL}/additionalFields/deleteAdditionalField/${id}`,
    headers: {
      Authorization: instance?.userData?.apiKey,
    },
  };

  axios(config)
    .then((res) => {
      if (res?.status === 200) {
        setAdditionalFields((prevState) => {
          const newArray = prevState.filter((element) => element._id !== id);

          return newArray;
        });

        messageApi.success("Campo adicional eliminado");
      }
    })
    .catch((error) => {
      console.error(error);
      messageApi.error("Error al eliminar campo adicional");
    })
    .finally(() => {
      if (setLoading) {
        setLoading(false);
      }
    });
}

export const changeVisibilityAdditionalField = async (
  instance,
  id,
  isVisible
) => {
  const config = {
    method: "put",
    url: `${API_URL}/additionalFields/changeVisibilityAdditionalField/${id}`,
    headers: {
      Authorization: instance?.userData?.apiKey,
    },
    data: {
      isVisible,
    },
  };

  const res = await axios(config);

  return res;
};
