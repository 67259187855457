import axios from "axios";
import { API_URL } from "../../config/ENV_CONSTS";

export const getBucketResources = async (key) => {
  try {
    if (key) {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${API_URL}/bucketResources/getBucketResources`,
        headers: {
          Authorization: key,
        },
      };
      const res = axios(config);

      return await res;
    }
  } catch (error) {
    console.log(error);
  }
};

export const deleteBucketResources = async (key, id, messageApi) => {
  try {
    if (key) {
      let config = {
        method: "delete",
        url: `${API_URL}/bucketResources/deleteFromBucket/${id}`,
        headers: {
          Authorization: key,
        },
      };
      const res = axios(config);

      return await res;
    }
  } catch (error) {
    console.log(error);
    if (error.response?.status === 409) {
      messageApi.error("El recurso se encuentra en uso, no se puede borrar");
    } else {
      messageApi.error("Ocurrió un error al eliminar un recurso");
    }
  }
};
