import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { GlobalContext } from "../components/context/GlobalContext";
import { API_URL } from "../config/ENV_CONSTS";

const useFetchCollection = (
  route,
  page,
  limit,
  search,
  filters = null,
  paused = false // En algunos casos donde sea necesario esperar a que se cargue algun tipo de filtro, etc.
) => {
  const { instance } = useContext(GlobalContext);

  const [data, setData] = useState({ items: [], totalItems: 0, totalPages: 0 });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await axios.get(`${API_URL}/${route}`, {
          params: {
            page,
            limit,
            search: search ? encodeURIComponent(search) : undefined,
            ...(filters || {}),
          },
          headers: {
            Authorization: instance?.userData?.apiKey,
          },
        });
        setData(response.data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    if (instance?.userData?.apiKey && !paused) {
      fetchData();
    }
  }, [route, page, limit, search, instance, filters, paused]);

  return { data, loading, error };
};

export default useFetchCollection;
