import axios from "axios";
import { API_URL } from "../../config/ENV_CONSTS";

export const getContactsLeads = async (apiKey) => {
  try {
    let config = {
      method: "get",
      url: `${API_URL}/contactsLeads/`,
      headers: {
        Authorization: apiKey,
        "Content-Type": "application/json",
      },
    };
    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const deleteLead = async (apiKey, id) => {
  try {
    let config = {
      method: "delete",
      url: `${API_URL}/contactsLeads/${id}`,
      headers: {
        Authorization: apiKey,
        "Content-Type": "application/json",
      },
    };
    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const getContactLead = async (instance, params) => {
  try {
    let config = {
      method: "get",
      url: `${API_URL}/contactsLeads/getContactLead`,
      headers: {
        Authorization: instance?.userData?.apiKey,
      },
      params,
    };
    const res = await axios(config);

    return res;
  } catch (err) {
    console.error(err);
  }
};
