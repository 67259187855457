import axios from "axios";
import { API_URL } from "../../config/ENV_CONSTS";

export const newMediaResource = async (key, data) => {
  try {
    let config = {
      method: "post",
      url: `${API_URL}/mediaResource/newMediaResource`,
      headers: {
        Authorization: key,
        "Content-Type": "multipart/form-data",
      },
      data: data,
    };

    let res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const deleteMediaResource = async (key, data) => {
  try {
    let config = {
      method: "delete",
      url: `${API_URL}/mediaResource/deleteMediaResource/${data}`,
      headers: {
        Authorization: key,
        "Content-Type": "application/json",
      },
    };

    let res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const getMediaByType = async (key) => {
  try {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${API_URL}/mediaResource/getMediaByType`,
      headers: {
        Authorization: key,
        "Content-Type": "application/json",
      },
    };

    let res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const editMediaResource = async (instance, data) => {
  try {
    const config = {
      method: "put",
      url: `${API_URL}/mediaResource/editMediaResource`,
      headers: {
        Authorization: instance?.userData?.apiKey,
      },
      data: data,
    };

    const res = await axios(config);

    return res;
  } catch (err) {
    console.error(err);
  }
};

export const FILE_TYPES = {
  image: {
    formatos: "jpeg, png",
    accept: "image/jpeg, image/png",
    maxSize: 5,
  },
  audio: {
    formatos: "aac, mp4, mpeg, amr, ogg (base)",
    accept: "audio/aac, audio/mp4, audio/mpeg, audio/amr, audio/ogg",
    maxSize: 16,
  },
  document: {
    formatos:
      "txt, pdf, doc, dot, xls, xlt, xla, xlsx, ppt, pot, pps, ppa, pptx, potx, ppsx",
    accept:
      "text/plain, application/pdf, application/vnd.ms-powerpoint, application/msword, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    maxSize: 100,
  },
  video: {
    formatos: "mp4, 3gp",
    accept: "video/mp4, video/3gp",
    maxSize: 16,
  },
};

export const FILE_TYPES_ASSISTANT = {
  document: {
    formatos:
      "c, cpp, cs, css, doc, docx, go, html, java, js, json, md, pdf, php, pptx, py, py, rb, sh, tex, ts, txt",
    accept:
      "text/x-c, text/x-c++, text/x-csharp, text/css, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/x-golang, text/html, text/x-java, text/javascript, application/json, text/markdown, application/pdf, text/x-php, application/vnd.openxmlformats-officedocument.presentationml.presentation, text/x-python, text/x-script.python, text/x-ruby, application/x-sh, text/x-tex, application/typescript, text/plain",
    maxSize: 100,
  },
};
