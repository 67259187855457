import axios from "axios";
import { API_URL } from "../../config/ENV_CONSTS";

export const fetchApplication = (
  instance,
  setApplication,
  messageApi,
  setLoadingApplication
) => {
  if (instance?._id) {
    setLoadingApplication(true);
    let count = 0;
    let fetch = () => {
      if (count <= 5) {
        count += 1;
        getApp(instance.userData?.apiKey)
          .then((res) => {
            if (res?.status === 200) {
              setApplication(res.data);
            } else {
              setTimeout(() => {
                fetch();
              }, 2000);
            }
          })
          .catch((error) => {
            setTimeout(() => {
              fetch();
            }, 2000);
          })
          .finally(() => setLoadingApplication(false));
      } else {
        messageApi.error("Error al obtener aplicación");
      }
    };
    fetch();
  }
};

const getApp = async (key) => {
  try {
    const config = {
      method: "get",
      url: `${API_URL}/apps/getApp`,
      headers: {
        Authorization: key,
        "Content-Type": "application/json",
      },
    };
    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const META_API_VERSION = "v19.0";
