import React, { useContext, useEffect, useState } from 'react';
import { Button, Divider } from 'antd';
import { GlobalContext } from '../../../../context/GlobalContext';
import { getLaboral } from '../utils/getLaboral';
import { getDireccion } from '../utils/getDireccion';
import { getProvincia } from '../utils/getProvincia';
import { getTipoDireccion } from '../utils/getTipoDireccion';
import { getCumpleanios } from '../utils/getCumpleanios';
import { formatPhoneNumber } from '../utils/formatPhoneNumber';
import { getAdditionalFields } from '../../../../../helpers/fetchData/fetchAdditionalFields';
import { EtiquetasXContactos } from '../../contactos/EtiquetasXContactos';
import { getAssociatedClients } from '../../../../../helpers/fetchData/fetchClients';

export const ShowContact = () => {
  const {
    rightDrawerConfig,
    setRightDrawerConfig,
    instance,
    messageApi,
    additionalFields,
    setAdditionalFields,
    setLoadingAdditionalFields
  } = useContext(GlobalContext);

  const [clients, setClients] = useState([]);
  const [loadingClients, setLoadingClients] = useState(false);

  const { item } = rightDrawerConfig;

  useEffect(() => {
    if (instance) {
      getAdditionalFields(
        instance,
        messageApi,
        setAdditionalFields,
        setLoadingAdditionalFields
      );
    }
  }, [instance]);

  useEffect(() => {
    if (instance?.userData?.apiKey && item) {
      setLoadingClients(true);
      getAssociatedClients(instance, item._id)
        .then((res) => {
          if (res?.status === 200) {
            setClients(res.data);
          }
        })
        .catch((err) => {
          console.error(err);
          messageApi.error(
            'No se pudo obtener los clientes asociados con el contacto'
          );
        })
        .finally(() => setLoadingClients(false));
    }
  }, [instance, item]);

  const { addresses, birthday, emails, name, org, phones, tags } = item;

  const contactAdditionalFields = item.additionalFields;

  return (
    <div className='ShowContact'>
      <div>
        <div className='show-contact-header'>
          <span className='show-contact-name'>{`${name?.first_name || ''} ${
            name?.middle_name || ''
          } ${name?.last_name || ''}`}</span>

          <span className='show-contact-job'>{getLaboral(org)}</span>

          {tags?.length > 0 && <EtiquetasXContactos data={tags} />}
        </div>

        <table className='show-contact-table'>
          <colgroup>
            <col style={{ width: '50%' }} />
            <col style={{ width: '50%' }} />
          </colgroup>
          <tbody>
            <tr>
              <td className='show-contact-label'>Fecha de nacimiento</td>
              <td className='show-contact-value'>{getCumpleanios(birthday)}</td>
            </tr>

            <tr>
              <td className='show-contact-label'>Teléfono</td>
              <td className='show-contact-value'>
                {formatPhoneNumber(phones?.[0]?.phone)}
              </td>
            </tr>

            <tr>
              <td className='show-contact-label'>Email</td>
              <td className='show-contact-value'>
                {emails?.[0]?.email || '-'}
              </td>
            </tr>

            <tr>
              <td className='show-contact-label'>Domicilio</td>
              <td className='show-contact-value'>
                {addresses?.[0]?.street ||
                addresses?.[0]?.city ||
                addresses?.[0]?.zip ? (
                  <>
                    <b>{getTipoDireccion(addresses[0]?.type)}</b>
                    <br />
                    {getDireccion(addresses[0])}
                    <br />
                    {getProvincia(addresses[0])}
                  </>
                ) : (
                  '-'
                )}
              </td>
            </tr>

            {additionalFields?.length > 0 &&
              contactAdditionalFields?.some((field) => field.value) &&
              contactAdditionalFields
                .filter((field) => field.value)
                .map((field) => {
                  const additionalField = additionalFields.find(
                    (af) => af._id === field._id
                  );

                  return additionalField ? (
                    <tr key={field._id}>
                      <td className='show-contact-label'>
                        {additionalField.name}
                      </td>
                      <td className='show-contact-value'>{field.value}</td>
                    </tr>
                  ) : null;
                })}
          </tbody>
        </table>

        <div className='show-contact-clients-container'>
          <Divider />

          <p className='show-contact-label'>Cuentas</p>

          {clients && clients?.length > 0 ? (
            clients.map((cliente) => (
              <p className='show-contact-value' key={cliente._id}>
                <b>{cliente.idSistema}</b>
                {` - ${cliente.name}`}
              </p>
            ))
          ) : (
            <i>No hay cuentas relacionadas</i>
          )}
        </div>
      </div>

      <div className='show-contact-close-btn'>
        <Button
          style={{
            flex: '1',
            marginBottom: 16
          }}
          type='primary'
          size='large'
          onClick={() =>
            setRightDrawerConfig({ visible: false, content: '', item: null })
          }
        >
          Cerrar
        </Button>
      </div>
    </div>
  );
};
